































import SafetyHookResourceListResultViewModel from '@/src/services/viewModel/resource/SafetyHookResourceListResultViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';
import Vue from 'vue';

export default Vue.extend({
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    accordionsOpen: {} as Record<string, boolean>,
    safetyHookList: new SafetyHookResourceListResultViewModel(),
    isLoading: false,
  }),
  computed: {
    innerValue: {
      get(): SiHaDocumentationResourceViewModel {
        return this.value ?? {};
      },
      set(value: SiHaDocumentationResourceViewModel) {
        this.$emit('input', value);
      },
    },
  },
  beforeMount() {
    this.$set(this.innerValue, 'safetyHookIds', this.innerValue.safetyHookIds ?? []);
  },
  async mounted() {
    this.isLoading = true;
    this.safetyHookList = await this.$service.api.siHaSafetyHook.getSafetyHookList();
    this.isLoading = false;
  },
  methods: {
    onHookSelectionChange(hookTypeId: number, selected: boolean) {
      if (selected) {
        this.innerValue.safetyHookIds!.push(hookTypeId);
      } else {
        this.innerValue.safetyHookIds = this.innerValue.safetyHookIds!.filter((id) => id !== hookTypeId);
      }
    },
  },
});
